import './App.css';
import Network from './network';
import SettingsWindow from './settingsWindow';
import { CButton, CCard, CCardBody, CContainer, CForm, CFormInput, 
 CImage, CSpinner, CModal, CModalHeader, CModalBody, CModalTitle, CModalFooter, 
 CCardTitle} from '@coreui/react';
import { useRef, useState, useEffect } from 'react';
import logo from './assets/logo.png';
import axios from 'axios';
import OpenAI from 'openai';

// No simple instruction and prompting in the style of zero-shot for direct answer
async function requestAPI(prompt, apiKey) {
  const openai = new OpenAI({
    apiKey: apiKey, 
    dangerouslyAllowBrowser: true
  });
  const chatCompletion = await openai.chat.completions.create({
    messages: [
    { role: 'user', content: prompt + "\nThe answer (in one sentence) is"}],
    model: 'gpt-4o-mini',
    seed: 0,
    temperature: 0
  });
  console.log(chatCompletion)
  return chatCompletion.choices[0].message.content
}
  

function App() {
  const [nodes, setNodes] = useState([]);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [finalNodes, setFinalNodes] = useState([]);
  const [isFinal, setIsFinal] = useState(true);
  const [answer, setAnswer] = useState(['','']);
  const [threshParam, setThreshParam] = useState(2.5);
  const [caption, setCaption] = useState('');
  const [apiKey, setAPIKey] = useState('');
  const [visible, setVisible] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const graphRef = useRef(null);

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  //choose the screen size 
  const handleResize = () => {
    if ((window.innerWidth < 720) || (window.innerHeight < 560)) {
      setIsSmall(true)
    } else {
      setIsSmall(false)
    }
  }

  const removeNode = event => {
    setNodes((nodes) => {
      let newNodes = [...nodes];
      newNodes.pop();
      return newNodes
    });
  };

  const handleSubmit = async (e) => {
    setVisible(false)
    setIsLoading(true);
    setFinalNodes([]);
    axios.get('https://cr-api.icosacomputing.com/cr/demo', 
      {params: {prompt: prompt, threshParam: threshParam, apiKey: apiKey, weight: 1},
       auth: {
        username: 'icosa',
        password: "tQ_g63ZrGROTlYhCpUf-EgpAP4z79C0ZmtAPbiEM5CM",
      }}
    )
      .then(async (response) => {
        console.log(response)
        setNodes(Array.from(response.data.initialReasons.slice(0, 200), (reasonTuple) => {
          let reason = reasonTuple[0]
          let count = reasonTuple[1]
          let firstWord = reason.split(' ')[0];
          return {id: reason, label: firstWord, caption: reason, data: {'weight': count}}}));
        setFinalNodes(Array.from(response.data.finalReasons, (reasonTuple) => {
          let reason = reasonTuple[0]
          let count = reasonTuple[1]
          let reasonStr = reason.match(/\).*/)[0]; // remove braces
          let weight = parseFloat(reason.match(/\(.*\)/)[0].slice(1,4));
          reasonStr = reasonStr.slice(2)
          let firstWord = reasonStr.split(' ')[0];
          return {id: reason + 'final', label: firstWord, caption: reasonStr, data: {'weight': count}}}));
        let fullAns = response.data.answer
        const directAns = await requestAPI(prompt, apiKey)
        setAnswer([fullAns, directAns]);
        setIsFinal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
  }

  const handleSolve = () => {
    if (finalNodes.length <= 1) {
      return
    }
    setIsFinal(true)
    setNodes(finalNodes)
    graphRef.current?.centerGraph()
  }

  if (isSmall) {
    return <CContainer fluid className="App">
      <div className='row' style={{marginTop: '1%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
            <CContainer sm style={{maxWidth: '20rem', marginRight: 'auto'}}>
                <CImage src={logo} fluid /> 
            </CContainer>
            {/*<CContainer fluid style={{maxWidth: 'fit-content', marginLeft: '0px', marginRight: 'auto',}}><h2>Icosa Computing</h2></CContainer>*/}
        </div>
        <div className='row' style={{marginTop: '2%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
          <p>Add reasoning capabilities to LLMs with Icosa Computing's Combinatorial Reasoner. Try it out here using GPT-4o-mini (API Key Needed)</p>
        </div>
        <CCard color='danger' fluid>
            <CCardTitle> Please use a bigger screen to access the demo </CCardTitle>
        </CCard>
    </CContainer>
  }

  return (
    <>
    <CContainer fluid className="App">
       <CModal
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <CModalHeader>
        <CModalTitle>Enter API Key</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormInput value={apiKey} 
                onChange={(e) => {setAPIKey(e.target.value)}} 
                placeholder='API Key'
                label="Please enter your OpenAI API Key Here. We will make 212 calls to GPT 4o Mini with this key:"
                />
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => {
          setVisible(false);
          setAPIKey('');
          }}>
          Back
        </CButton>
        <CButton color="primary" onClick={handleSubmit}>Submit</CButton>
      </CModalFooter>
    </CModal>
        <div className='row' style={{marginTop: '1%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
            <CContainer sm style={{maxWidth: '20rem', marginRight: 'auto'}}>
                <CImage src={logo} fluid /> 
            </CContainer>
            {/*<CContainer fluid style={{maxWidth: 'fit-content', marginLeft: '0px', marginRight: 'auto',}}><h2>Icosa Computing</h2></CContainer>*/}
        </div>
        <div className='row' style={{marginTop: '2%', marginLeft: 'auto', maxWidth: '50%', marginRight: 'auto',}}>
          <p>Add reasoning capabilities to LLMs with Icosa Computing's Combinatorial Reasoner. Try it out here using GPT-4o-mini (API Key Needed)</p>
        </div>
        <div className='row' style={{marginTop: '0%'}}>
          
          <div className='col' style={{marginLeft: '25%', maxWidth: '50%', marginRight: '0px',}}>
            <CForm onSubmit={(e) => {
              e.preventDefault();
              setVisible(true);
            }}>
                {/*<CFormSelect 
                label="Prompt:"
                options={[
                  { label: 'Write your prompt here...', disabled: true },
                  ...promptOptions
                ]} 
                value={prompt} 
                onChange={(e) => {setPrompt(e.target.value)}} 
                placeholder='Write your prompt here...'
                />*/}
                {<CFormInput value={prompt} 
                onChange={(e) => {setPrompt(e.target.value)}} 
                placeholder='Write your prompt here...'
                label="Please write your reasoning intensive question here:"
                />}
                <div style={{marginLeft: 'auto', maxWidth: '5%', marginRight: 'auto',}}>
                <CButton type='submit'>Go!</CButton>
                </div>
            </CForm>
          </div>
          <div className='col' style={{ marginLeft: '4%', maxWidth: '18%', marginRight: '3%',}}>
              <SettingsWindow onThreshParamChange={(e) => {setThreshParam(e.target.value)}} threshParam={threshParam} 
              disabled={isLoading || !isFinal}/>
          </div>
        </div>
        <div className='row'>
        {
        isLoading ?  
        <div className='col' style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: 'auto', marginTop: '15%'}}>
          <CSpinner />
        </div>
           : 
           <>
        <div className='col' style={{position: 'fixed', marginLeft: '79%', maxWidth: '18%',}}>
            {(answer[0].length > 0) && isFinal ? <>
            <CCard >
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    RESPONSE WITHOUT CR: <br/>
                    { answer[1]}
                </div>
              </CCardBody>
            </CCard> 
            </>
            :
            <></>
            }
        </div>
    
        <myDiv className='col' style={{position: 'fixed', marginLeft: '2%', maxWidth: '18%'}}>
            {(answer[0].length > 0) && isFinal ? <>
            <CCard style={{border: 'solid purple'}} >       
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    RESPONSE WITH CR: <br/>
                    {answer[0]}
                </div>
              </CCardBody>
            </CCard> 
            </>
            :
            <></>
            }
        </myDiv>
        
        <div className='col' style={{position: 'fixed', marginLeft: '2%', maxWidth: '18%', marginTop: '20%'}}>
            <CCard>
              <CCardBody>
                <div style={{maxHeight: "15em", overflow: 'scroll'}}>
                    FULL REASON: {caption}
                </div>
              </CCardBody>
            </CCard>
            <p></p> 
        </div>
      
      <div>
      </div>
      <div className='col' style={{position:'fixed', marginLeft: '19%', maxWidth: 'fit-content', marginRight: 'auto', marginTop: '2%'}}>
         <Network nodes={nodes} fullyConnected={false} caption={caption} setCaption={setCaption} graphRef={graphRef}/>
      </div>
      </>
      }
      </div>
    </CContainer>
    {isFinal ? 
      /*<p style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: 'auto', marginTop: '30%'}}>{answer}</p>*/<></> :
      <div style={{position: 'fixed', marginLeft: '35%', maxWidth: 'fit-content', marginRight: '35%', marginBottom: '2rem', bottom: '0px', maxHeight: '10%'}}>
        <div style={{marginLeft: 'auto', maxWidth: 'fit-content', marginRight: 'auto'}}>
         <CButton onClick={handleSolve} color='primary'>Solve</CButton>
         </div>
         <p>Only 200 reasons are shown in order to minimize crowding</p>
        </div>
      }
      </>
  );
};

export default App;
